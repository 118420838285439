import React from "react";
import styled from "styled-components";

import { Maybe, SanityStringAndImageBlock } from "@graphql-types";
import { Container, GridContainer, P } from "@util/standard";
import { Image, Link, SuccessToastMessage } from "@global";
import { colors } from "@util/constants";
import { isBrowser } from "@util/helper";
import { toast } from "react-toastify";

interface Props {
  data: Maybe<SanityStringAndImageBlock>[];
}

const ImageWrapper = styled(Container)`
  height: 100px;
  width: 100px;
  margin: auto;
  .gatsby-image-wrapper {
    border-radius: 50%;
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.navy};
  div > div {
    opacity: 1;
    transition: all 0.2s;
  }
  &:hover {
    filter: none;
    div > div {
      opacity: 0.6;
      transition: all 0.2s;
    }
  }
  transition: all 0.2s;
`;

const StyledContainer = styled(Container)`
  flex-direction: column;
  color: ${colors.navy};
  cursor: pointer;
  div > div {
    opacity: 1;
    transition: all 0.2s;
  }
  &:hover {
    filter: none;
    div > div {
      opacity: 0.6;
      transition: all 0.2s;
    }
  }
  transition: all 0.2s;
`;

function PromotionalProductsList({ data }: Props) {
  const handleRedirect = (link: string) => {
    if (isBrowser() && link) {
      toast.success(<SuccessToastMessage />);
      setTimeout(() => {
        window.open(link as string, "_blank");
      }, 3000);
    }
    return;
  };

  return (
    <Container width="70%" tabletWidth="80%" margin="0 auto 120px auto">
      <GridContainer
        repeat={6}
        width="100%"
        tabletRepeat={3}
        mobileRepeat={2}
        rowGap={40}
        columnGap={40}
      >
        {data.map(product => {
          if (product == null) return null;

          return (
            <div key={product._key}>
              {product.link?.internalLink ? (
                <StyledLink {...product.link}>
                  <Container flexDirection="column" key={product._key}>
                    {product.productImage && (
                      <ImageWrapper>
                        <Image data={product.productImage} />
                      </ImageWrapper>
                    )}
                    {product.productName && (
                      <P margin="20px 0 0 0" textAlign="center" fontWeight="medium">
                        {product.productName}
                      </P>
                    )}
                  </Container>
                </StyledLink>
              ) : (
                <StyledContainer
                  onClick={() =>
                    product?.link?.externalLink && handleRedirect(product.link?.externalLink)
                  }
                >
                  {product.productImage && (
                    <ImageWrapper>
                      <Image data={product.productImage} />
                    </ImageWrapper>
                  )}
                  {product.productName && (
                    <P margin="20px 0 0 0" textAlign="center" fontWeight="medium">
                      {product.productName}
                    </P>
                  )}
                </StyledContainer>
              )}
            </div>
          );
        })}
      </GridContainer>
    </Container>
  );
}

export default PromotionalProductsList;
