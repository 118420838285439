import React from "react";
import { PageProps, graphql } from "gatsby";
import styled from "styled-components";
import { toast } from "react-toastify";

import { Query } from "@graphql-types";
import { BlocksContent, Button, Hero, SuccessToastMessage } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { Container, P } from "@util/standard";
import PromotionalProductsList from "@components/promotionalProducts/promotionalProductsList";
import { isBrowser } from "@util/helper";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props extends PageProps {
  data: Query;
}

const TextWrapper = styled(Container)`
  flex-direction: column;
  width: 70%;
  margin: 100px auto 150px auto;
  h2,
  h3 {
    margin-top: 0px;
  }
  p {
    width: 80%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    p {
      width: 100%;
    }
  }
`;

export default function PromotionalProductsPage({ data }: Props) {
  if (data.allSanityPromotionalProducts == null) return null;

  const { seo, hero, promoProductsList, textBlock, promoLink } =
    data.allSanityPromotionalProducts.nodes[0];

  const handleRedirect = () => {
    if (isBrowser() && promoLink && promoLink.externalLink) {
      toast.success(<SuccessToastMessage />);
      setTimeout(() => {
        window.open(promoLink.externalLink as string, "_blank");
      }, 3000);
    }
    return;
  };

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        {textBlock && (
          <TextWrapper>
            <BlocksContent data={textBlock} />
            {promoLink && promoLink.externalLink && promoLink.linkText && (
              <Button
                margin="20px 0 0 0"
                linkText={promoLink.linkText as string}
                onClick={handleRedirect}
              />
            )}
          </TextWrapper>
        )}
        {promoProductsList && <PromotionalProductsList data={promoProductsList} />}
      </Layout>
    </>
  );
}

export const query = graphql`
  query allSanityPromotionalProductsQuery($slug: String, $countryCode: String) {
    allSanityPromotionalProducts(
      filter: { slug: { current: { eq: $slug } }, region: { countryCode: { eq: $countryCode } } }
    ) {
      nodes {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        promoProductsList {
          productName
          _type
          _key
          link {
            ...sanityLink
          }
          productImage {
            ...sanityFullWidthImage
          }
        }
        textBlock {
          _rawBlockContent
          _key
          _type
        }
        promoLink {
          ...sanityLink
        }
      }
    }
  }
`;
